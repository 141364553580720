.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
  position: relative;
}

.content {
  max-width: 60rem;
  min-width: 30rem;
}

.title {
  margin-bottom: 18px;
}

.avatarWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-bottom: 8px;
}

.avatar {
  cursor: pointer;
  width: 100px;
  height: 100px;
}

.header {
  display: flex;
  align-items: center;
}

.progress {
  margin-left: 16px;
}

.pillsBlock {
  max-width: 30rem;
  text-align: center;
}

.pill {
  margin: 5px;
  cursor: pointer;
}

.dateField {
  margin-top: 8px;
}